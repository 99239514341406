import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ModalNotificationPage } from './pages/user/modal/modal-notification/modal-notification.page';
import { ModalBoletoPage } from './pages/user/modal/modal-boleto/modal-boleto.page';
import { ModalUploadNfPage } from './pages/user/dashboard/commission-report/modal-upload-nf/modal-upload-nf.page';
import { FilePage } from './pages/user/dashboard/sales-support/file/file.page';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ModalBonusesPage } from './pages/user/modal/modal-bonuses/modal-bonuses.page';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { ModalAlertPage } from './pages/public/modal/modal-alert/modal-alert.page';

@NgModule({
  declarations: [
    AppComponent,
    ModalNotificationPage,
    ModalBoletoPage,
    ModalUploadNfPage,
    ModalBonusesPage,
    ModalAlertPage,
    FilePage,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false,
    }),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SocialSharing,
    Clipboard,
    FileTransfer,
    FileTransferObject,
    File,
    FileOpener,
    InAppBrowser,
    HeaderColor,
    Device,
    AppVersion,
    Market,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
