/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  storageWeb: sessionStorage,
  storageMobile: localStorage,
  // AUTH_SERVER_ADDRESS: 'https://administradora.easyplan.com.br',
  SERVER_ADDRESS:
  'https://apis.easyplan.com.br:3088/',
  // 'https://10.61.1.119:3088/',
  AUTH_SERVER_ADDRESS:
    'https://apis.grupoafinidade.com.br:200/restprd-afinidade',
  AUTH_SERVER_ADDRESS_NEW:
    'https://administradora.easyplan.com.br/AreaCorretor',
  Authorization: 'Basic YWRtaW46MTIzNDU2',
  expires: 3600000,
  logo: 'assets/logo/logo.png',
  logoH: 'assets/logo/Logo_EasyPlan.png',
  pandadoc: {
    Key: 'API-Key 34645e4fe95397f6169bd8af43a58a090e66a11d',
    storage: localStorage,
    url: 'https://api.pandadoc.com/public/v1/documents',
  },
  contact: {
    whatsapp:
      'https://api.whatsapp.com/send/?phone=556182593281&text&type=phone_number&app_absent=0',
    whatsappCliente:
      'https://api.whatsapp.com/send/?phone=551140037172&text&type=phone_number&app_absent=0',
    facebook: 'https://www.facebook.com/easyplanadm/',
    instagram: 'https://www.instagram.com/easyplanadm/',
    linkedin:
      'https://www.linkedin.com/company/easyplan-administradora-de-benef%C3%ADcios/',
    website: 'https://www.easyplan.com.br/',
    youtube: 'https://www.youtube.com/@easyplan8468',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
